<template>
  <div v-frag class='d-flex flex-row'>
    <v-row class='px-6 mb-8'>
      <v-card v-if="isModuleActive('volunteering')"
              class='mt-8 mr-4 d-flex flex-row'
              height='170px'
              width='48%'>
        <v-icon class='primary white--text px-2' size='60'>
          mdi-hand-heart-outline
        </v-icon>
        <v-col class='px-0'>
          <v-simple-table>
            <thead>
            <tr>
              <th class='text-left'>Volunteer Hour</th>
              <th class='text-left'>Hours</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>Total hours volunteering</td>
              <td>{{ volunteerCard.totalHoursVolunteering }}</td>
            </tr>
            <tr>
              <td>Total volunteering hours requested</td>
              <td>{{ volunteerCard.totalVolunteeringHoursRequested }}</td>
            </tr>
            </tbody>
          </v-simple-table>
        </v-col>
      </v-card>
      <v-card v-if="isModuleActive('donations')"
              class='mt-8 d-flex flex-row'
              height='170px'
              width='48%'>
        <v-icon class='primary white--text px-2' size='60'>
          mdi-currency-usd
        </v-icon>
        <v-col class='px-0'>
          <v-simple-table>
            <thead>
            <tr>
              <th class='text-left'>Donation</th>
              <th class='text-left'>Dollars</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>Total donations</td>
              <td class='font-weight-bold primary--text'>
                {{ formatMoney(donationCard.totalDonations) }}
              </td>
            </tr>
            <tr>
              <td>Total Monthly Recurring Donations</td>
              <td class='font-weight-bold primary--text'>
                {{ formatMoney(donationCard.totalMonthlyRecurring) }}
              </td>
            </tr>
            </tbody>
          </v-simple-table>
        </v-col>
      </v-card>
    </v-row>

    <v-card class='ma-4 pa-6'>
      <v-data-table :headers='headers' :items='charities' width='100%'>
        <template v-slot:[`item.donation`]='{ item }'>
          <span> {{ formatMoney(item.donation) }} </span>
        </template>
        <template v-slot:item.restricted='{ item }'>
          <input
            :id='item.id'
            :checked='!item.restricted'
            :value='item.restricted'
            type='checkbox'
            @change='setCharity(item.id, item.restricted)'
            @input="$emit('input', reversedValue)"
          />
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>
<script>
import memberService from '../../services/member/index'
import {mapGetters} from 'vuex'

export default {
  data: () => ({
    headers: [
      {
        text: 'Charity',
        align: 'start',
        value: 'name',
        divider: true,
        width: '25%',
        class: 'header-text'
      },
      {
        text: 'Address',
        align: 'start',
        value: 'address',
        divider: true,
        width: '25%',
        class: 'header-text'
      },
      {
        text: 'Donation',
        align: 'start',
        value: 'donation',
        divider: true,
        width: '25%',
        class: 'header-text'
      },
      {
        text: 'Total Hours Volunteering',
        align: 'start',
        value: 'volunteering',
        divider: true,
        width: '25%',
        class: 'header-text'
      },
      {
        text: 'Allowed',
        align: 'start',
        value: 'restricted',
        divider: true,
        width: '25%',
        class: 'header-text'
      }
    ],
    charities: [],
    volunteerCard: {},
    donationCard: {}
  }),
  mounted() {
    window.scrollTo(0, 0)
  },
  computed: {
    ...mapGetters(['getModules']),
    reversedValue() {
      return this.value === this.trueValue ? this.falseValue : this.trueValue
    }
  },
  created() {
    this.initialize()
  },
  methods: {
    async initialize() {
      await this.$store.dispatch('addModules')
      await this.getCharityStatisticsHeaders()
      await this.getCharitiesDetails()
    },
    isModuleActive(module) {
      return this.getModules.includes(module)
    },
    setCharity(id, result) {
      if (result === 0) {
        result = 1
      } else {
        result = 0
      }
      try {
        memberService.setCharity({
          id: id,
          restricted: result
        })

      } catch (err) {
        console.log(err)
      }
      this.initialize()
    },

    async getCharityStatisticsHeaders() {
      const memberId = this.$store.getters.user.profile_id.member_id
      try {
        const cards = await memberService.charityStatisticsHeaders(memberId)
        this.volunteerCard = cards.volunteeringHours
        this.donationCard = cards.totalDonations
      } catch (err) {
        console.log(err)
      }
    },
    async getCharitiesDetails() {
      const memberId = this.$store.getters.user.profile_id.member_id
      try {
        const charityTable = await memberService.charitiesDetail(memberId)
        this.charities = charityTable.charities
      } catch (err) {
        console.log(err)
      }
    },
    formatMoney: (value) => {
      if (typeof value == 'number') {
        return value.toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD'
        })
      }
    }
  }
}
</script>
<style>
.text-left {
  font-size: 18px !important;
}
.header-text {
  font-size: 15px !important;
}
</style>
